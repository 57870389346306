import React, { Component } from 'react';
import Keycloak from 'keycloak-js';
import UserInfo from './UserInfo';
import Logout from './Logout';

const authTypes = {
    sso: 'sso',
    login: 'login'
}

class Secured extends Component {

    constructor(props) {
        super(props);
        this.state = {
            keycloak: null,
            authenticated: false,
            authType: authTypes.sso
        };
    }

    componentDidMount() {
        const { history } = this.props
        const keycloak = Keycloak('/keycloak.json');

        const urlParams = new URLSearchParams(window.location.search);
        const jwt = urlParams.get('jwt');

        if (jwt) {
            console.log({ jwt })
            history.push({
                search: ''
            })

            // examples of jwt tokens, push one of this in url query params
            // const jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJyb2xlIjoiUk9MRV9VU0VSIiwidXNlcklkIjoiMTEtMDAwMDAwMDAwNjI3NzY4In0.wwjo-7iVg9aCghdkGr8XG4IKN9wmk_9J4ZEFDhVOV-RWEF6YopOepKVeNl5xQXbxtpUuWfztShsw9V_uqh6NMg'
            // const jwt2 = 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIwMS0wMDAwMDAwMDE4MTU3NTAiLCJyb2xlIjoiUk9MRV9VU0VSIiwidXNlcklkIjoiMDEtMDAwMDAwMDAxODE1NzUwIn0.wvdQ7W9zXHTsUK9122KfkxNFzAzuTdadMhyQmrm7C5b9MGH90j4ivw2m_bFvwl9R9uzqgl5YzFSLzDzYOzia5A'

            const response_type = 'code'
            const scope = 'openid'
            const client_id = 'myapp'
            const redirect_uri = `${document.location.origin}/keycloakauth.html?jwt=${jwt}`

            // TODO keycloak init doesnt work without first request
            fetch(`https://auth.easykassa.ru/auth/realms/master/protocol/openid-connect/auth?response_type=${response_type}&scope=${scope}&client_id=${client_id}&redirect_uri=${redirect_uri}`, {
                method: 'GET',
                'accept-encoding': 'gzip, deflate, br',
                'accept': '*/*',
                credentials: 'include',
                mode: 'no-cors'
            }).then(() => {
                keycloak.init({
                    onLoad: 'check-sso',
                    silentCheckSsoRedirectUri: redirect_uri,
                }).then(authenticated => {
                    console.log({ authenticated })
                    this.setState({keycloak: keycloak, authenticated: authenticated})
                })
            })
        } else {
            // this.setState({
            //     authType: authTypes.login
            // })

            keycloak.init({ onLoad: 'login-required' }).then(authenticated => {
                console.log({ authenticated })
                this.setState({keycloak: keycloak, authenticated: authenticated})
            })
        }
    }

    submit = async () => {
        const login = document.getElementById('login').value
        const password = document.getElementById('password').value

        const searchParams = new URLSearchParams(`username=${login}&password=${password}&grant_type=password&client_id=myapp`)

        const response = await fetch(`https://auth.easykassa.ru/auth/realms/master/protocol/openid-connect/token`, {
            method: 'POST',
            body: searchParams,
            headers: {
                'accept': 'application/json',
            },
        })
        try {
            const result = await response.json()
            console.log({ result })
            // TODO push and get tokens from localStorage
            // TODO check token in localStorage after reload app (which request?)
            // TODO catch authError and show login form
            // TODO fetch userInfo after reload page (if it need)
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        if(this.state.keycloak) {
            if(this.state.authenticated) return (
                <div>
                    <p>This is a Keycloak-secured component of your application. You shouldn't be able
                        to see this unless you've authenticated with Keycloak.</p>
                    <UserInfo keycloak={this.state.keycloak} />
                    <Logout keycloak={this.state.keycloak} />
                </div>
            ); else return (<div>Unable to authenticate!</div>)
        }
        if (this.state.authType === authTypes.login) {
            return (
              <div>
                  <div>
                      <label htmlFor="login">Login</label>
                      <input type="text" id="login" />
                  </div>
                  <div>
                      <label htmlFor="password">Password</label>
                      <input type="password" id="password" />
                  </div>
                  <button onClick={this.submit}>Submit</button>
              </div>
            )
        }
        return (
            <div>Initializing Keycloak...</div>
        );
    }
}
export default Secured;